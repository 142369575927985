import React from 'react';
import NotFound from 'views/NotFound';
import Minimal from 'layouts/Minimal';
import WithLayout from 'WithLayout';

const FourOFourPage = () => {
  return (
    <WithLayout
      component={NotFound}
      layout={Minimal}
    />
  )
};

export default FourOFourPage;